export const ADMINS_LIST_TABLE_LABELS = [
  {
    accessor: 'name',
    isSortable: true,
    label: 'Name',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Created',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Role',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Email Address',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Mobile Number',
  },
];

export const LAWYERS_LIST_TABLE_LABELS = [
  {
    accessor: 'name',
    isSortable: true,
    label: 'Name',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Created',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Role',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Email Address',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Mobile Number',
  },
];

export const CASES_LIST_TABLE_LABELS = [
  {
    accessor: 'name',
    isSortable: true,
    label: 'Case',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Plaintiff',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Defendant',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Created',
  },
  {
    accessor: 'filedAt',
    isSortable: true,
    label: 'Filed',
  },
  {
    accessor: 'sol',
    isSortable: true,
    label: 'SOL',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Gross Settlement',
  },
];

export const DEDUCTIONS_LIST_TABLE_LABELS = [
  {
    accessor: 'caseName',
    isSortable: true,
    label: 'Case',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Plaintiff',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Defendant',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Entry Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Type',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Amount',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'paidAt',
    isSortable: true,
    label: 'Payment Date',
  },
];

export const SETTLEMENTS_LIST_TABLE_LABELS = [
  {
    accessor: 'caseName',
    isSortable: true,
    label: 'Case',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Client',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Defendant',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Entry Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Settled Amount',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Attorneys Fees',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Deductions',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Net Settlement',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'paidAt',
    isSortable: true,
    label: 'Payment Date',
  },
];

export const DOCUMENTS_LIST_TABLE_LABELS = [
  {
    accessor: 'plaintiff',
    isSortable: true,
    label: 'Client',
  },
  {
    accessor: 'sentAt',
    isSortable: true,
    label: 'Request Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Document Name',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'completedAt',
    isSortable: true,
    label: 'Completion Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Completion Time',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'File',
  },
];

export const SIGNATURES_LIST_TABLE_LABELS = [
  {
    accessor: 'plaintiff',
    isSortable: true,
    label: 'Client',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Request Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Document Name',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'completedAt',
    isSortable: true,
    label: 'Completion Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Completion Time',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Document Id',
  },
];

export const DATA_LIST_TABLE_LABELS = [
  {
    accessor: 'client',
    isSortable: true,
    label: 'Client',
  },
  {
    accessor: 'sentAt',
    isSortable: true,
    label: 'Request Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Case Type',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Items',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Completion',
  },
  {
    accessor: 'completedAt',
    isSortable: true,
    label: 'Completion Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Completion Time',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Data File',
  },
];

export const FIRM_CLIENTS_LIST_TABLE_LABELS = [
  {
    accessor: 'name',
    isSortable: true,
    label: 'Name',
  },
  {
    accessor: 'email',
    isSortable: false,
    label: 'Email',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Created',
  },
  // {
  //   accessor: '',
  //   isSortable: false,
  //   label: 'Status',
  // },
  {
    accessor: '',
    isSortable: false,
    label: 'Active Cases',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Gross Settlements',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Net Settlements',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Wallet Balance',
  },
];

export const FIRM_DEFENDANTS_LIST_TABLE_LABELS = [
  {
    accessor: 'name',
    isSortable: true,
    label: 'Name',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Created',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Active Cases',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Gross Settlements',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Net Settlements',
  },
];

export const FIRM_LAWYERS_LIST_TABLE_LABELS = [
  {
    accessor: 'name',
    isSortable: true,
    label: 'Name',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Created',
  },
  {
    accessor: 'role',
    isSortable: false,
    label: 'Role',
  },
  {
    accessor: 'status',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'email',
    isSortable: false,
    label: 'Email Address',
  },
  {
    accessor: 'phone',
    isSortable: false,
    label: 'Mobile Number',
  },
];

export const FIRM_DOCUMENTS_LIST_TABLE_LABELS = [
  {
    accessor: 'plaintiff',
    isSortable: true,
    label: 'Client',
  },
  {
    accessor: 'sentAt',
    isSortable: true,
    label: 'Request Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Document Name',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'completedAt',
    isSortable: true,
    label: 'Completion Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Completion Time',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'File',
  },
];

export const CASES_DATA_REQUEST_LIST_TABLE_LABELS = [
  {
    accessor: 'sentAt',
    isSortable: true,
    label: 'Request Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Case Type',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Items',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Completion',
  },
  {
    accessor: 'completedAt',
    isSortable: true,
    label: 'Completion Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Completion Time',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Data File',
  },
];

export const FIRM_SIGNATURE_LIST_TABLE_LABELS = [
  {
    accessor: 'plaintiff',
    isSortable: true,
    label: 'Client',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Request Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Document Name',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'completedAt',
    isSortable: true,
    label: 'Completion Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Completion Time',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Document Id',
  },
];

export const CASES_DEDUCTIONS_LIST_TABLE_LABELS = [
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Entry Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Type',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Assignment',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Amount',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'paidAt',
    isSortable: true,
    label: 'Payment Date',
  },
];

export const CASES_SETTLEMENTS_LIST_TABLE_LABELS = [
  {
    accessor: '',
    isSortable: false,
    label: 'Defendant',
  },
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Entry Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Gross Settlement',
  },
  {
    accessor: '',
    isSortable: false,
    // eslint-disable-next-line quotes
    label: "Attorneys' Fees",
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Deductions',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Net Settlement',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'paidAt',
    isSortable: true,
    label: 'Payment Date',
  },
];

export const CASES_DOCUMENTS_LIST_TABLE_LABELS = [
  {
    accessor: 'sentAt',
    isSortable: true,
    label: 'Request Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Document Name',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'completedAt',
    isSortable: true,
    label: 'Completion Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Completion Time',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'File',
  },
];

export const CASES_SIGNATURE_LIST_TABLE_LABELS = [
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Request Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Document Name',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: 'completedAt',
    isSortable: true,
    label: 'Completion Date',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Completion Time',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Document Id',
  },
];

export const CLIENTS_TRANSACTIONS_LIST_TABLE_LABELS = [
  {
    accessor: 'lastUpdatedOn',
    isSortable: true,
    label: 'Created',
  },
  {
    accessor: 'accountId',
    isSortable: false,
    label: 'Account ID',
  },
  {
    accessor: 'transactionId',
    isSortable: false,
    label: 'Transaction ID',
  },
  {
    accessor: 'transaction',
    isSortable: false,
    label: 'Transaction',
  },
  {
    accessor: 'memo',
    isSortable: false,
    label: 'Memo',
  },
  {
    accessor: 'type',
    isSortable: false,
    label: 'Type',
  },
  {
    accessor: 'amount',
    isSortable: false,
    label: 'Amount',
  },
  {
    accessor: 'runningBalance',
    isSortable: false,
    label: 'Running Balance',
  },
];

export const FIRM_BANKING_UNRESTRICTED_ACCOUNTS_LIST_TABLE_LABELS = [
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Created',
  },
  {
    accessor: 'name',
    isSortable: false,
    label: 'Name',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Account Number',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Routing Number',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: '',
    isSortable: false,
    label: '',
  },
];

export const FIRM_BANKING_TRUST_ACCOUNTS_LIST_TABLE_LABELS = [
  {
    accessor: 'createdAt',
    isSortable: true,
    label: 'Created',
  },
  {
    accessor: 'name',
    isSortable: false,
    label: 'Name',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Account Number',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Routing Number',
  },
  {
    accessor: '',
    isSortable: false,
    label: 'Status',
  },
  {
    accessor: '',
    isSortable: false,
    label: '',
  },
];
