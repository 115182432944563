import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, Theme } from '@mui/material';

import { sendForgotPasswordEmail } from '../../api/authApi/authApi';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormTextField from '../../components/formFields/FormTextField';
import { FORGOT_PASSWORD_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { forgotPasswordFormSchema } from '../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../hooks/useSnackBar';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles()((theme: Theme) => ({
  signInBackground: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to left bottom, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0))',
    backgroundColor: '#282B3A',

    '@media (max-width: 640px)': {
      padding: '0px 16px',
    },

    '@media (max-height: 420px)': {
      height: 'auto',
    },
  },
  signInHeadline: {
    fontWeight: '600',
    fontSize: '64px',
    lineHeight: '72px',
    color: theme.palette.common.white,
    fontFamily: 'Poppins',

    '@media (max-width: 640px)': {
      width: '100%',
      textAlign: 'center',
      fontSize: '32px',
      lineHeight: '40px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '@media (max-height: 475px)': {
      marginTop: '40px',
    },
  },
  signInFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.light,
    borderRadius: '16px',
    border: `1px solid ${theme.palette.secondary.dark}`,
    padding: '32px',
    marginTop: '60px',
    maxWidth: '480px',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '64px',

    '@media (max-width: 640px)': {
      padding: '16px',
    },
  },
  signInFormHeadline: {
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '34px',
    letterSpacing: '-0.5px',
    color: theme.palette.common.white,
  },
}));

const ForgotPasswordView = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const { setAlert } = useSnackBar();
  const { checkAuth, loading, isAuth } = useAuth();

  useEffect(() => {
    checkAuth();
  }, []);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(forgotPasswordFormSchema),
    defaultValues: FORGOT_PASSWORD_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = formMethods;

  const onSubmit = (values: { email: string }) => {
    sendForgotPasswordEmail(values)
      .then((res) => {
        if (res.status === 204) {
          navigate('/resetPassword', {
            state: {
              email: values.email,
            },
          });
          setAlert((prev) => ({
            ...prev,
            message: 'New verification code has been sent successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        if (err) {
          setAlert((prev) => ({
            ...prev,
            message: err.response.data.message,
            type: 'error',
          }));
        }
      });
  };

  if (loading) return null;
  if (isAuth) return <Navigate to="/cases" />;

  return (
    <Box className={styles.classes.signInBackground}>
      <Typography className={styles.classes.signInHeadline}>Forgot Password?</Typography>

      <FormProvider {...formMethods}>
        <form className={styles.classes.signInFormContainer}>
          <Typography className={styles.classes.signInFormHeadline}>
            Please enter your email below
          </Typography>
          <Box mt="24px">
            <FormTextField name="email" label="Email address" />
          </Box>

          <Box mt="32px">
            <FormSubmitButton
              buttonText="Continue"
              disabled={!isValid || isSubmitting}
              onClick={handleSubmit(onSubmit)}
              fontFamily="Inter"
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default ForgotPasswordView;
