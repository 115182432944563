import { Navigate, useLocation, useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import PasswordChecklist from 'react-password-checklist';

import { Box, Typography, Theme, useTheme, useMediaQuery } from '@mui/material';

import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import useAuth from '../../hooks/useAuth';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_PASSWORD_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { resetPasswordFormSchema } from '../../helpers/formSchemas/formSchemas';
import FormPasswordField from '../../components/formFields/FormPasswordField';
import { useEffect, useState } from 'react';
import useSnackBar from '../../hooks/useSnackBar';
import { confirmForgotPassword } from '../../api/authApi/authApi';

const useStyles = makeStyles()((theme: Theme) => ({
  signInBackground: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to left bottom, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0))',
    padding: '0 16px',

    '@media (max-width: 480px)': {
      '@media (max-height: 780px)': {
        height: 'auto',
      },
    },

    '@media (max-width: 640px)': {
      '@media (max-height: 640px)': {
        height: 'auto',
      },
    },

    '@media (min-width: 641px)': {
      '@media (max-height: 830px)': {
        height: 'auto',
      },
    },
  },
  signInHeadline: {
    fontWeight: '600',
    fontSize: '64px',
    lineHeight: '72px',
    color: theme.palette.common.white,
    fontFamily: 'Poppins',

    '@media (max-width: 640px)': {
      width: '100%',
      textAlign: 'center',
      fontSize: '32px',
      lineHeight: '40px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '@media (max-height: 900px)': {
      marginTop: '60px',
    },
  },
  signInFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.light,
    borderRadius: '16px',
    border: `1px solid ${theme.palette.secondary.dark}`,
    padding: '32px',
    marginTop: '60px',
    maxWidth: '480px',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '64px',

    '@media (max-width: 640px)': {
      padding: '16px',
    },
  },
  signInFormHeadline: {
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '34px',
    letterSpacing: '-0.7px',
    color: theme.palette.common.white,
  },
  signInFormText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    letterSpacing: '0px',
    color: theme.palette.common.white,
  },
  signInFormLink: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    letterSpacing: '0px',
    color: theme.palette.info.main,
    textDecoration: 'none',
  },
  signInFormBottomLink: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: '0px',
    color: theme.palette.info.main,
    textAlign: 'center',
    textDecoration: 'none',
  },
  passwordChecklist: {
    li: {
      alignItems: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '17px',
      letterSpacing: '0px',

      '&.invalid': {
        '& span': {
          opacity: 1,
          color: theme.palette.error.main,
        },
      },

      '&.valid': {
        '& span': {
          opacity: 1,
          color: theme.palette.success.main,
        },
      },
    },
  },
}));

const AuthTemporaryPasswordView = () => {
  const theme = useTheme();
  const styles = useStyles();
  const sm = useMediaQuery('(max-width:640px)');
  const navigate = useNavigate();
  const location = useLocation();

  const { setAlert } = useSnackBar();
  const { checkAuth, loading, isAuth } = useAuth();

  useEffect(() => {
    checkAuth();
  }, []);

  const [passwordsAreValid, setPasswordsAreValid] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(resetPasswordFormSchema),
    defaultValues: RESET_PASSWORD_FORM_DEFAULT_VALUES,
  });

  const {
    watch,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = formMethods;

  const newPasswordValue = watch('newPassword');
  const repeatNewPasswordValue = watch('repeatNewPassword');

  const onSubmit = (values: { code: string; newPassword: string; repeatNewPassword: string }) => {
    const submitData = {
      email: location.state?.email,
      newPassword: values.newPassword,
      passwordRecoveryCode: values.code,
    };

    confirmForgotPassword(submitData)
      .then((res) => {
        if (res.status === 204) {
          navigate('/sign-in');
          setAlert((prev) => ({
            ...prev,
            message: 'Password has been reset successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        if (err) {
          if (
            err.response.data.code === 'INCORRECT_RECOVERY_CODE' ||
            err.response.data.code === 'USER_NOT_FOUND'
          ) {
            setAlert((prev) => ({
              ...prev,
              message: err.response.data.message,
              type: 'error',
            }));
          } else {
            navigate('/sign-in');
            setAlert((prev) => ({
              ...prev,
              message: err.response.data.message,
              type: 'error',
            }));
          }
        }
      });
  };

  if (loading) return null;
  if (isAuth) return <Navigate to="/cases" />;

  return (
    <Box className={styles.classes.signInBackground}>
      <Typography className={styles.classes.signInHeadline}>Change Password</Typography>

      <FormProvider {...formMethods}>
        <form className={styles.classes.signInFormContainer}>
          <Typography className={styles.classes.signInFormHeadline}>
            Please enter verification code and your new password below
          </Typography>
          <Box mt="24px">
            <FormPasswordField name="code" label="Verification Code" />
          </Box>
          <Box mt="24px">
            <FormPasswordField name="newPassword" label="New Password" />
          </Box>
          <Box mt="24px">
            <FormPasswordField name="repeatNewPassword" label="Repeat New Password" />
          </Box>

          <Box mt={sm ? '16px' : '32px'} ml="4px">
            <PasswordChecklist
              className={styles.classes.passwordChecklist}
              rules={['lowercase', 'number', 'capital', 'specialChar', 'minLength', 'match']}
              value={newPasswordValue}
              valueAgain={repeatNewPasswordValue}
              minLength={8}
              validColor={theme.palette.success.main}
              invalidColor={theme.palette.error.main}
              iconSize={14}
              onChange={(isValid) => setPasswordsAreValid(isValid)}
              messages={{
                minLength: 'Password must contain at least 8 characters',
                number: 'Password must contain a number',
                capital: 'Password must contain an upper case letter',
                lowercase: 'Password must contain a lower case letter',
                match: 'Passwords must match',
                specialChar: 'Password must contain a special character',
              }}
            />
          </Box>

          <Box mt={sm ? '16px' : '32px'}>
            <FormSubmitButton
              buttonText="Continue"
              disabled={!isValid || !passwordsAreValid || isSubmitting}
              onClick={handleSubmit(onSubmit)}
              fontFamily="Inter"
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default AuthTemporaryPasswordView;
