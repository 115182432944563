import { motion, useScroll, useTransform } from 'framer-motion';
import { makeStyles } from 'tss-react/mui';

import { Box, Typography } from '@mui/material';

import { ReactComponent as Frame1 } from './assets/howItWorksAssets/frame-1.svg';
import { ReactComponent as Frame2 } from './assets/howItWorksAssets/frame-2.svg';
import { ReactComponent as Frame3 } from './assets/howItWorksAssets/frame-3.svg';
import { ReactComponent as Frame4 } from './assets/howItWorksAssets/frame-4.svg';
import { ReactComponent as Frame5 } from './assets/howItWorksAssets/frame-5.svg';
import { ReactComponent as Frame6 } from './assets/howItWorksAssets/frame-6.svg';
import { ReactComponent as Frame7 } from './assets/howItWorksAssets/frame-7.svg';

import { ReactComponent as NextArrow1 } from './assets/howItWorksAssets/next-arrow-1.svg';
import { ReactComponent as NextArrow2 } from './assets/howItWorksAssets/next-arrow-2.svg';
import { ReactComponent as NextArrow3 } from './assets/howItWorksAssets/next-arrow-3.svg';
import { ReactComponent as NextArrow4 } from './assets/howItWorksAssets/next-arrow-4.svg';
import { ReactComponent as NextArrow5 } from './assets/howItWorksAssets/next-arrow-5.svg';
import { ReactComponent as NextArrow6 } from './assets/howItWorksAssets/next-arrow-6.svg';

import { useCallback, useRef } from 'react';

const useStyles = makeStyles()(() => ({
  headlineText: {
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '64px',
    color: '#282B3A',
    textAlign: 'center',
  },
}));

interface HowItWorksBlockProps {
  windowWidth: number;
}

interface HorizontalScrollCarouselProps {
  windowWidth: number;
}

const HowItWorksBlock = ({ windowWidth }: HowItWorksBlockProps) => {
  return <HorizontalScrollCarousel windowWidth={windowWidth} />;
};

const HorizontalScrollCarousel = ({ windowWidth }: HorizontalScrollCarouselProps) => {
  const styles = useStyles();
  const targetRef = useRef(null);

  const { scrollYProgress } = useScroll({
    layoutEffect: true,
    target: targetRef,
    smooth: -300,
  });

  const handleTransformBreakpoints = useCallback(() => {
    if (windowWidth > 1024) {
      return ['1%', '-60%'];
    }

    if (windowWidth <= 426) {
      return ['1%', '-89%'];
    }

    if (windowWidth <= 768) {
      return ['1%', '-80%'];
    }

    if (windowWidth <= 1024) {
      return ['1%', '-72%'];
    }

    return ['1%', '-60%'];
  }, [windowWidth]);

  const x = useTransform(scrollYProgress, [0, 1], handleTransformBreakpoints());

  const animationProps = {
    initial: { opacity: 0 },
    whileInView: {
      opacity: 1,
      transition: { duration: 0.5, ease: 'easeIn' },
    },
    viewport: { once: true },
  };

  return (
    <section
      ref={targetRef}
      style={{
        position: 'relative',
        height: '150vh',
        marginTop: '160px',
        marginBottom: '130px',
      }}
      id="howitworks"
    >
      <Typography className={styles.classes.headlineText}>How it works</Typography>
      <div
        style={{
          position: 'sticky',
          display: 'flex',
          height: 'screen',
          alignItems: 'center',
          overflow: 'hidden',
          paddingTop: '64px',
          top: '250px',
        }}
      >
        <motion.div style={{ x, display: 'flex', alignItems: 'center' }}>
          <motion.div {...animationProps}>
            <Box className="frame-1">
              <Frame1 />
            </Box>
          </motion.div>

          <motion.div {...animationProps}>
            <Box className="next-arrow-1">
              <NextArrow1 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="frame-2">
              <Frame2 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="next-arrow-2">
              <NextArrow2 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="frame-3">
              <Frame3 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="next-arrow-3">
              <NextArrow3 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="frame-4">
              <Frame4 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="next-arrow-4">
              <NextArrow4 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="frame-5">
              <Frame5 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="next-arrow-5">
              <NextArrow5 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="frame-6">
              <Frame6 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="next-arrow-6">
              <NextArrow6 />
            </Box>
          </motion.div>
          <motion.div {...animationProps}>
            <Box className="frame-7">
              <Frame7 />
            </Box>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default HowItWorksBlock;
