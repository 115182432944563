import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import PinInput from '../../components/PinInput';
import useSnackBar from '../../hooks/useSnackBar';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '802px',
      boxSizing: 'border-box',
      padding: '32px',
      gap: '32px',
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: '16px',
      backgroundColor: theme.palette.primary.light,
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '34px',
    lineHeight: '40.8px',
    padding: '32px 0px 0px 0px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '0px',
  },
  dialogContentText: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
  },
  text: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16.8px',
    color: theme.palette.text.disabled,
  },
  pinInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',

      '@media (max-width: 550px)': {
        paddingRight: '16px',
      },
    },
  },
  defaultButtonStyle: {
    height: '48px',
    maxWidth: '416px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.2px',
    textTransform: 'none',
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  submitButton: {
    background: theme.palette.info.main,

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  cancelButton: {
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
}));

interface FormProps {
  numbers: Array<{
    value: string;
  }>;
  confirmedNumbers: Array<{
    value: string;
  }>;
}

const pinInputFormSchema = yup
  .array()
  .of(
    yup
      .object()
      .shape({
        value: yup.string().required(),
      })
      .required(),
  )
  .required();

const setNewPinFormSchema = yup
  .object()
  .shape({
    numbers: pinInputFormSchema.required(),
    confirmedNumbers: pinInputFormSchema.required(),
  })
  .required();

const formDefaultValues = {
  numbers: new Array(4).fill({ value: '' }),
  confirmedNumbers: new Array(4).fill({ value: '' }),
};

interface SetPinCodePopupProps {
  readonly isOpen: boolean;
  onMainButtonClick: (newPin: string) => void;
  onSecondaryButtonClick?: () => void;
}

const SetPinCodePopup = ({
  isOpen,
  onMainButtonClick,
  onSecondaryButtonClick,
}: SetPinCodePopupProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const formMethods = useForm<FormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(setNewPinFormSchema),
    defaultValues: formDefaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  const onSubmit = (values: FormProps) => {
    const pin = values.numbers.map((num) => num.value).join('');
    const confirmedPin = values.confirmedNumbers.map((num) => num.value).join('');

    if (pin === confirmedPin) {
      onMainButtonClick(confirmedPin);
    } else {
      setAlert((prev) => ({
        ...prev,
        message: 'PIN codes do not match.',
        type: 'error',
      }));
    }

    reset(formDefaultValues);
  };

  const handleCancel = () => {
    reset(formDefaultValues);
    onSecondaryButtonClick?.();
  };

  return (
    <Dialog className={classes.dialogContainer} open={isOpen} onClose={handleCancel}>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={(theme) => ({
          position: 'absolute',
          top: 32,
          right: 32,
          color: theme.palette.common.white,
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle className={classes.dialogTitle}>Set PIN</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogContentText}>
          4-digit PIN for settlement
        </DialogContentText>
        <FormProvider {...formMethods}>
          <PinInput name="numbers" />
          <Box className={classes.pinInputWrapper}>
            <Typography className={classes.text}>Confirm PIN</Typography>
            <PinInput name="confirmedNumbers" />
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button
          className={clsx(classes.defaultButtonStyle, classes.submitButton)}
          onClick={handleSubmit(onSubmit)}
          type="submit"
        >
          Submit
        </Button>
        <Button
          className={clsx(classes.defaultButtonStyle, classes.cancelButton)}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetPinCodePopup;
