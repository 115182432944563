import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

import AccountDeletionRequestForm from './AccountDeletionRequestForm';

import { ReactComponent as LogoIcon } from '../LandingView/assets/logo.svg';

const useStyles = makeStyles()(() => ({
  pageBackground: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to left bottom, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0))',
    backgroundColor: '#282B3A',
    padding: '20px',

    '@media (max-width: 415px)': {
      '@media (max-height: 900px)': {
        height: 'auto',
      },
    },

    '@media (max-width: 510px)': {
      '@media (max-height: 780px)': {
        height: 'auto',
      },
    },

    '@media (min-width: 641px)': {
      '@media (max-height: 800px)': {
        height: 'auto',
      },
    },
  },

  signInLogoContainer: {
    '@media (max-height: 700px)': {
      marginTop: '40px',
    },

    marginBottom: '60px',
    svg: {
      color: '#FFF',
    },
  },
}));

const DataSafetyView = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.pageBackground}>
      <Box className={classes.signInLogoContainer}>
        <Link to="/">
          <LogoIcon />
        </Link>
      </Box>
      <AccountDeletionRequestForm />
    </Box>
  );
};

export default DataSafetyView;
