import { makeStyles } from 'tss-react/mui';

import { Box, Button, Theme, Typography, useTheme } from '@mui/material';

import {
  FirmBankingSystemAccountData,
  SYSTEM_ACCOUNT_LABELS,
  SYSTEM_ACCOUNT_STATUSES,
} from './types';

import { numberToUSDCurrency } from '../../helpers/numberToUSDCurrency';
import { capitalizeText } from '../../helpers/capitalizeText';
import { Dispatch, SetStateAction } from 'react';

const useStyles = makeStyles<{ chipColor?: string }>()((theme: Theme, { chipColor }) => ({
  systemAccountWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '20px',
    background: theme.palette.primary.light,
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '12px',
    minHeight: '200px',
  },
  systemAccountsColoredLabel: {
    color: 'rgba(37, 157, 168, 0.72)',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '120%',
    fontFamily: 'Poppins',
  },
  systemAccountsSmallLabel: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '120%',
    color: '#93959E',
    letterSpacing: '0.2px',
    fontFamily: 'Inter',
  },
  systemAccountsLabel: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  systemAccountsBalanceText: {
    fontSize: '34px',
    fontWeight: 600,
    lineHeight: '120%',
    fontFamily: 'Poppins',
    color: theme.palette.common.white,
    marginTop: '32px',
    marginBottom: '12px',
    textAlign: 'center',
  },
  noAccountText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.3)',
    maxWidth: '260px',
    fontFamily: 'Inter',
  },
  systemAccountStatusChip: {
    padding: '4px 12px',
    borderRadius: '12px',
    border: `1px solid ${chipColor}`,
    background: `${chipColor}19`,
    color: chipColor,
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter',
    lineHeight: '120%',
  },
  accountsInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: '32px',
    textAlign: 'right',
  },
  withdrawButton: {
    background: theme.palette.info.main,
    borderRadius: '12px',
    border: `1px solid ${theme.palette.info.main}`,
    padding: '10px 32px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%',
    fontFamily: 'Inter',
    color: theme.palette.common.white,
    textTransform: 'none',

    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.info.main,
      border: `1px solid ${theme.palette.info.main}`,
    },

    '&.Mui-disabled': {
      border: 'none',
      color: theme.palette.text.disabled,
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
}));

interface FirmBankingSystemAccountContainerProps {
  label: string;
  accountData: FirmBankingSystemAccountData | null;
  setSelectedAccountType: Dispatch<SetStateAction<string>>;
  setIsWithdrawalPopupOpen: Dispatch<SetStateAction<boolean>>;
}

const FirmBankingSystemAccountContainer = ({
  accountData,
  label,
  setSelectedAccountType,
  setIsWithdrawalPopupOpen,
}: FirmBankingSystemAccountContainerProps) => {
  const { classes } = useStyles({ chipColor: '' });

  const formattedLabel = SYSTEM_ACCOUNT_LABELS[label as keyof typeof SYSTEM_ACCOUNT_LABELS];

  const onWithdrawButtonClick = () => {
    setIsWithdrawalPopupOpen(true);
    setSelectedAccountType(label);
  };

  return (
    <>
      {!accountData ? (
        <Box className={classes.systemAccountWrapper}>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px"></Box>
          <Typography className={classes.systemAccountsColoredLabel}>{formattedLabel}</Typography>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography className={classes.noAccountText}>
              No information about this account, or it has not been created yet.
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box className={classes.systemAccountWrapper}>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px">
            <Typography className={classes.systemAccountsColoredLabel}>{formattedLabel}</Typography>

            <SystemAccountStatusChip status={accountData.status} />
          </Box>

          <Box className={classes.accountsInfoContainer}>
            <Typography
              className={classes.systemAccountsSmallLabel}
            >{`Account Number: ${accountData.accountNumber}`}</Typography>

            <Typography className={classes.systemAccountsSmallLabel}>
              {`Routing Number (ABA):${accountData.routingNumber}`}
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            flexDirection="column"
            marginTop="auto"
          >
            <Typography className={classes.systemAccountsBalanceText}>
              {numberToUSDCurrency(accountData.balance)}
            </Typography>

            <Button
              disableRipple
              className={classes.withdrawButton}
              disabled={!accountData.withdrawalAvailable || Number(accountData.balance) === 0}
              onClick={onWithdrawButtonClick}
            >
              Withdraw Funds
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

interface SystemAccountStatusChipProps {
  status: string;
}

const SystemAccountStatusChip = ({ status }: SystemAccountStatusChipProps) => {
  const theme = useTheme();

  const SystemAccountStatusesChipColor = {
    CLOSED: theme.palette.error.main,
    BLOCKED: theme.palette.error.main,
    CLOSURE_INITIATED: theme.palette.warning.main,
    INACTIVE: theme.palette.warning.main,
    ACTIVE: theme.palette.success.main,
  };

  const currentChipColor =
    SystemAccountStatusesChipColor[status as keyof typeof SYSTEM_ACCOUNT_STATUSES];

  const styles = useStyles({ chipColor: currentChipColor });

  return (
    <Box className={styles.classes.systemAccountStatusChip}>
      {capitalizeText(SYSTEM_ACCOUNT_STATUSES[status as keyof typeof SYSTEM_ACCOUNT_STATUSES])}
    </Box>
  );
};

export default FirmBankingSystemAccountContainer;
