import api from '../service/api.service';

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/v1/fo/auth`;

export const onLogIn = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/login`;

  return await api.put(targetUrl, data);
};

export const setPermamentPassword = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/set-permanent-password`;

  return await api.put(targetUrl, data);
};

export const send2FAcode = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/send-verification-code`;

  return await api.put(targetUrl, data);
};

export const refreshUserToken = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/refresh-token`;

  return await api.put(targetUrl, data);
};

export const sendForgotPasswordEmail = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/forgot-password`;

  return await api.put(targetUrl, data);
};

export const confirmForgotPassword = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/confirm-forgot-password`;

  return await api.put(targetUrl, data);
};
