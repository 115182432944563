import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import FirmBankingSystemAccountContainer from './FirmBankingSystemAccountContainer';
import FirmUnrestrictedAccountsView from './unrestrictedAccountsView/FirmUnrestrictedAccountsView';
import FirmTrustAccountsView from './trustAccountsView/FirmTrustAccountsView';
import {
  AccountTypeShortCuts,
  FirmBankingData,
  FirmBankingWithdrawFormSubmitProps,
  UnrestrictedAccountOptionsData,
} from './types';

import { getFirmBankingInfo, withrdawFirmFunds } from '../../api/firmsApi/firmsApi';
import PaginationProvider from '../../providers/PaginationProvider';
import useSnackBar from '../../hooks/useSnackBar';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { firmWithdrawFundsFormSchema } from '../../helpers/formSchemas/formSchemas';
import { FIRM_WITHRDAW_FUNDS_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { AxiosError } from 'axios';
import { ErrorData } from '../../types';
import { checkUserPin } from '../../api/usersApi/usersApi';
import VerificationPopup from '../../components/VerificationPopup';
import FirmBankingWithdrawPopup from './withdrawFundsForm/FirmBankingWithdrawPopup';
import FirmBankingWithdrawForm from './withdrawFundsForm/FirmBankingWithdrawForm';

const useStyles = makeStyles()((theme: Theme) => ({
  systemAccountsWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '20px',
  },
  systemAccountContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  systemAccountsBlockLabel: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '120%',
    color: theme.palette.common.white,
    fontFamily: 'Poppins',
  },
  systemAccountsLabel: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  systemAccountsInnerContainer: {
    display: 'flex',
    height: '100%',
    gap: '20px',

    '@media (max-width: 1100px)': {
      flexWrap: 'wrap',
      maxWidth: '500px',
    },
  },
}));

export const FirmBankingAccountsView = () => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const firmId = localStorage.getItem('firmId');

  const [bankingData, setBankingData] = useState<FirmBankingData>();
  const [selectedAccountType, setSelectedAccountType] = useState('');
  const [isWithdrawalPopupOpen, setIsWithdrawalPopupOpen] = useState<boolean>(false);
  const [isVerificationPopupOpen, setIsVerificationPopupOpen] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [shortUnrestrictedAccountData, setShortUnrestrictedAccountData] = useState<
    UnrestrictedAccountOptionsData[]
  >([]);

  const userId = localStorage.getItem('userId');
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(firmWithdrawFundsFormSchema),
    defaultValues: FIRM_WITHRDAW_FUNDS_FORM_DEFAULT_VALUES,
  });

  const { handleSubmit } = formMethods;

  const onSubmitForm = (values: FirmBankingWithdrawFormSubmitProps) => {
    setIsFormSubmitting(true);

    const sumbitData = {
      fromSystemAccountType:
        AccountTypeShortCuts[values.fromSystemAccountType as keyof typeof AccountTypeShortCuts],
      toUnrestrictedAccountId: Number(values.toUnrestrictedAccountId),
      amount: Number(values.amount),
    };

    withrdawFirmFunds(String(firmId), sumbitData)
      .then((res) => {
        if (res.status === 202) {
          setAlert((prev) => ({
            ...prev,
            message: 'Withdraw has been operated successfully.',
            type: 'success',
          }));
          setIsFormSubmitting(false);
          setIsWithdrawalPopupOpen(false);
        }
      })
      .catch((err) => {
        if (err) {
          setAlert((prev) => ({
            ...prev,
            message:
              'You’re not authorized to withdraw funds. Contact your administrator or client representative.',
            type: 'error',
          }));
          setIsFormSubmitting(false);
        }
      });
  };

  const handleConfirmPin = (pin: string) => {
    if (!userId) return;
    checkUserPin(Number(userId), pin)
      .then((response) => {
        if (response.data.isValid) {
          setIsVerificationPopupOpen(false);
          handleSubmit(onSubmitForm)();
        } else {
          setAlert((prev) => ({
            ...prev,
            message: 'Invalid pin. Please try again.',
            type: 'error',
          }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      });
  };
  const handleCloseVerificationPopup = () => setIsVerificationPopupOpen(false);

  const fetchBankingData = () => {
    getFirmBankingInfo(firmId as string).then((res) => {
      setBankingData(res.data);
    });
  };

  useEffect(() => {
    fetchBankingData();
  }, []);

  return (
    <Box className={classes.systemAccountContainer}>
      <Typography className={classes.systemAccountsBlockLabel} mb="12px">
        System Accounts
      </Typography>

      <Box className={classes.systemAccountsInnerContainer}>
        {bankingData?.accounts && (
          <>
            {Object.entries(bankingData?.accounts).map(([accountType, accountData]) => (
              <React.Fragment key={accountType}>
                <FirmBankingSystemAccountContainer
                  setSelectedAccountType={setSelectedAccountType}
                  setIsWithdrawalPopupOpen={setIsWithdrawalPopupOpen}
                  label={accountType}
                  accountData={accountData}
                />
              </React.Fragment>
            ))}
          </>
        )}
      </Box>

      <PaginationProvider key="bankingUnrestrictedAccountsView">
        <FirmUnrestrictedAccountsView
          setShortUnrestrictedAccountData={setShortUnrestrictedAccountData}
        />
      </PaginationProvider>

      <PaginationProvider key="bankingTrustAccountsView">
        <FirmTrustAccountsView />
      </PaginationProvider>

      <FormProvider {...formMethods}>
        <FirmBankingWithdrawPopup
          isOpen={isWithdrawalPopupOpen}
          setSelectedAccountType={setSelectedAccountType}
          handleClosePopup={() => setIsWithdrawalPopupOpen(false)}
          selectedAccountType={selectedAccountType}
          isFormSubmitting={isFormSubmitting}
          handleOpenVerificationPopup={() => setIsVerificationPopupOpen(true)}
          headlineText="Withdraw Funds"
        >
          <FirmBankingWithdrawForm
            unrestrictedAccountOptionsData={shortUnrestrictedAccountData}
            selectedAccountType={selectedAccountType}
          />
        </FirmBankingWithdrawPopup>
      </FormProvider>

      <VerificationPopup
        isOpen={isVerificationPopupOpen}
        onMainButtonClick={handleConfirmPin}
        onSecondaryButtonClick={handleCloseVerificationPopup}
      />
    </Box>
  );
};

export default FirmBankingAccountsView;
