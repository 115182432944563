import api from '../service/api.service';

import { PaginationQueryParams } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';

const BASE_URL = '/v1';

export const getFirmsList = async ({
  size,
  page,
  count,
  match,
  filters,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const targetUrl = `${BASE_URL}/firms?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }${queryFilters || ''}`;

  return await api.get(targetUrl);
};

export const createFirmRecord = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/firms`;

  return await api.post(targetUrl, data);
};

export const getFirmRecord = async (firmId: string) => {
  const targetUrl = `${BASE_URL}/firms/${firmId}`;

  return await api.get(targetUrl);
};

export const updateFirmRecord = async (firmId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/firms/${firmId}`;

  return await api.put(targetUrl, data);
};

export const getFirmBankingInfo = async (firmId: string) => {
  const targetUrl = `${BASE_URL}/firms/${Number(firmId)}/banking`;

  return await api.get(targetUrl);
};

export const withrdawFirmFunds = async (firmId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/bo/firms/${firmId}/banking/withdraw`;
  return await api.put(targetUrl, data);
};
