import moment from 'moment';
import * as yup from 'yup';

import { QuestionnaireItemFormat } from '../../types';
import { ExpectedAnswer } from '../../constants/constants';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateDateField = (minDateValue?: string, maxDateValue?: string, isOptional = false) => {
  return yup
    .mixed()
    .test('isValidDate', 'Invalid date format', function (value) {
      if (isOptional && (value === null || value === '')) {
        return true;
      }
      if (value === null || value === '') {
        return this.createError({
          message: 'Date field is required',
          path: this.path,
        });
      }

      return yup.date().isValidSync(value);
    })
    .test('isWithinRange', 'Invalid date', function (value) {
      const minDate = new Date(minDateValue || '1900-01-01');
      const maxDate = new Date(maxDateValue || '2100-01-01');

      if (value === null || value === '') {
        // Skip validation if the field is null or empty, as it's already handled in the previous test
        return true;
      }

      const date = new Date(value as string);

      return date >= minDate && date <= maxDate;
    });
};

export const manageAccountFormSchema = yup.object({
  id: yup.number().required('Required *'),
  role: yup.string().required('Required *'),
  firstName: yup
    .string()
    .max(100, 'Must be less or equal to 100 characters')
    .required('Required *'),
  lastName: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email format').required('Required *'),
  phone: yup.string().required('Required *'),
  isPinSet: yup.boolean().required(),
});

export const createUserFormSchema = yup.object({
  id: yup.number(),
  firstName: yup
    .string()
    .max(100, 'Must be less or equal to 100 characters')
    .required('Required *'),
  lastName: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email format').required('Required *'),
  phone: yup
    .string()
    .required('Required *')
    .matches(/^\+\d{1,15}$/, 'The phone number must start with + and contain up to 15 digits'),
  role: yup.string().required('Required *'),
  firmId: yup.string().required('Required *'),
});

export const defendantFormSchema = yup.object({
  id: yup.string(),
  referenceId: yup
    .string()
    .max(100, 'Must be less or equal to 100 characters')
    .required('Required *'),
  name: yup.string().max(300, 'Must be less or equal to 300 characters').required('Required *'),
  firmId: yup.string().required('Required *'),
});

export const clientCreateFormSchema = yup.object({
  referenceId: yup
    .string()
    .max(100, 'Must be less or equal to 100 characters')
    .required('Required *'),
  firstName: yup
    .string()
    .max(40, 'Must be less or equal to 40 characters')
    .required('Required *')
    .matches(/^[a-zA-Z0-9_,.&()/"'\s]+$/, 'Valid characters: [a-z A-Z 0-9 _ , . & () / " \']'),
  middleName: yup
    .string()
    .max(40, 'Must be less or equal to 40 characters')
    .matches(/^[a-zA-Z0-9_,.&()/"'\s]*$/, 'Valid characters: [a-z A-Z 0-9 _ , . & () / " \']'),
  lastName: yup
    .string()
    .max(40, 'Must be less or equal to 40 characters')
    .required('Required *')
    .matches(/^[a-zA-Z0-9_,.&()/"'\s]+$/, 'Valid characters: [a-z A-Z 0-9 _ , . & () / " \']'),
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email format').required('Required *'),
  phone: yup
    .string()
    .required('Required *')
    .matches(/^\+1\d{10}$/, 'Invalid phone number'),
  address1: yup
    .string()
    .min(4, 'Must be between 4 and 40 characters ')
    .max(40, 'Must be less or equal to 40 characters')
    .required('Required *'),
  address2: yup.string().max(30, 'Must be less or equal to 30 characters'),
  city: yup
    .string()
    .max(45, 'Must be less or equal to 45 characters')
    .required('Required *')
    // eslint-disable-next-line quotes
    .matches(/^[a-zA-Z.'-\s]+$/, `Valid characters: a-z, A-Z, ., ', -`),
  state: yup.string().required('Required *'),
  zip: yup
    .string()
    .required('Required *')
    .matches(
      /^(?!0{5})(\d{5}|\d{5}-\d{4})$/,
      'Invalid Zip Code. Correct format: XXXXX or XXXXX-XXXX',
    ),
  birthdate: yup
    .date()
    .transform((_, rawValue) => {
      const correctDate = moment(rawValue, ['mm-dd-yyyy']).toDate();
      return correctDate;
    })
    .min(moment(new Date(1900, 1, 1)).toDate(), 'Date is too early')
    .typeError('Invalid date')
    .required('Required *')
    .nullable()
    .max(moment(), 'Date is too far'),
  ssn: yup
    .string()
    .required('Required *')
    .matches(
      /^(?!0{3})\d{3}-(?!0{2})\d{2}-(?!0{4})\d{4}$/,
      'Invalid SSN. Correct format: XXX-XX-XXXX',
    ),
});

export const clientEditFormSchema = yup.object({
  id: yup.string().required(),
  referenceId: yup
    .string()
    .max(100, 'Must be less or equal to 100 characters')
    .required('Required *'),
  firstName: yup
    .string()
    .max(40, 'Must be less or equal to 40 characters')
    .required('Required *')
    .matches(/^[a-zA-Z0-9_,.&()/"'\s]+$/, 'Valid characters: [a-z A-Z 0-9 _ , . & () / " \']'),
  middleName: yup
    .string()
    .max(40, 'Must be less or equal to 40 characters')
    .matches(/^[a-zA-Z0-9_,.&()/"'\s]*$/, 'Valid characters: [a-z A-Z 0-9 _ , . & () / " \']'),
  lastName: yup
    .string()
    .max(40, 'Must be less or equal to 40 characters')
    .required('Required *')
    .matches(/^[a-zA-Z0-9_,.&()/"'\s]+$/, 'Valid characters: [a-z A-Z 0-9 _ , . & () / " \']'),
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email format').required('Required *'),
  phone: yup
    .string()
    .required('Required *')
    .matches(/^\+1\d{10}$/, 'Invalid phone number'),
  address1: yup
    .string()
    .min(4, 'Must be between 4 and 40 characters ')
    .max(40, 'Must be less or equal to 40 characters')
    .required('Required *'),
  address2: yup.string().max(30, 'Must be less or equal to 30 characters'),
  city: yup
    .string()
    .max(45, 'Must be less or equal to 45 characters')
    .required('Required *')
    // eslint-disable-next-line quotes
    .matches(/^[a-zA-Z.'-\s]+$/, `Valid characters: a-z, A-Z, ., ', -`),
  state: yup.string().required('Required *'),
  zip: yup
    .string()
    .required('Required *')
    .matches(
      /^(?!0{5})(\d{5}|\d{5}-\d{4})$/,
      'Invalid Zip Code. Correct format: XXXXX or XXXXX-XXXX',
    ),
});

export const personalInfoFormSchema = yup.object().shape({
  birthdate: yup.mixed().nullable(),
  ssn: yup.string().required(),
});

export const casesFormSchema = yup.object({
  id: yup.string(),
  referenceId: yup
    .string()
    .max(100, 'Must be less or equal to 100 characters')
    .required('Required *'),
  caseTypeCategory: yup.string().required('Required *'),
  type: yup.object().shape({
    id: yup.string().required('Required *'),
    label: yup.string().required('Required *'),
  }),
  trustAccount: yup
    .object()
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .nullable(),
  firmId: yup.string().required('Required *'),
  filedAt: validateDateField(undefined, undefined, true).nullable(),
  sol: validateDateField(undefined, undefined, true).nullable(),
  clientId: yup.object().shape({
    id: yup.string().required('Required *'),
    label: yup.string().required('Required *'),
  }),
  defendant: yup.array().of(
    yup.object().shape({
      defendantId: yup.object().shape({
        id: yup.string().required('Required *'),
        label: yup.string().required('Required *'),
      }),
      gross: yup.string().matches(/^\d+$/, 'Only numbers'), // TODO: currency input
    }),
  ),
  secondaryFirms: yup.array().of(
    yup.object().shape({
      firmId: yup.object().shape({
        id: yup.string(),
        label: yup.string(),
      }),
      firmRole: yup.string().required(),
    }),
  ),
});

export const casesEditFormSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
  referenceId: yup
    .string()
    .max(100, 'Must be less or equal to 100 characters')
    .required('Required *'),
  caseTypeCategory: yup.string().required('Required *'),
  type: yup
    .object()
    .shape({ id: yup.string().required('Required *'), label: yup.string().required('Required *') }),
  trustAccount: yup.object().shape({ id: yup.string(), label: yup.string() }).nullable(),
  filedAt: validateDateField(undefined, undefined, true).nullable(),
  sol: validateDateField(undefined, undefined, true).nullable(),
  clientId: yup
    .object()
    .shape({ id: yup.string().required('Required *'), label: yup.string().required('Required *') }),
  defendant: yup.array().of(
    yup.object().shape({
      defendantId: yup.object().shape({
        id: yup.string().required('Required *'),
        label: yup.string().required('Required *'),
      }),
      gross: yup.string().matches(/^\d+$/, 'Only numbers'), // TODO: currency input
    }),
  ),
  secondaryFirms: yup.array().of(
    yup.object().shape({
      firmId: yup.object().shape({
        id: yup.string(),
        label: yup.string(),
      }),
      firmRole: yup.string().required(),
    }),
  ),
  paymentStarted: yup.boolean().required(),
});

export const deductionsCreateFormSchema = yup.object({
  id: yup.string(),
  isAssigned: yup.bool(),
  amount: yup.string().required('Required *'), // TODO: currency input
  type: yup.string().required('Required *'),
  defendant: yup.object({
    id: yup.string(),
    label: yup.string(),
  }),
});

export const settlementsCreateFormSchema = yup.object({
  id: yup.string(),
  amount: yup.string().required('Required *'), // TODO: currency input
  defendant: yup.object().shape({
    id: yup.string().required('Required *'),
    label: yup.string().required('Required *'),
  }),
});

export const deductionsEditFormSchema = yup.object({
  id: yup.string(),
  caseReferenceId: yup.string(),
  caseSystemName: yup.string(),
  firm: yup.string(),
  isAssigned: yup.bool(),
  defendant: yup.object({
    id: yup.string(),
    label: yup.string(),
  }),
  amount: yup.string().required('Required *'), // TODO: currency input
  type: yup.string().required('Required *'),
});

export const settlementsEditFormSchema = yup.object({
  id: yup.string(),
  caseReferenceId: yup.string(),
  caseSystemName: yup.string(),
  firm: yup.string(),
  plaintiff: yup.object({
    id: yup.string(),
    fullName: yup.string(),
  }),
  defendant: yup.object({
    id: yup.string(),
    label: yup.string(),
  }),
  deduction: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      createdAt: yup.string(),
      type: yup.string(),
      amount: yup.number().max(9999999999.99, 'Max value 9999999999.99'),
      isAssigned: yup.string(),
      isAdded: yup.boolean(),
    }),
  ),
});

export const documentsCreateFormSchema = yup.object({
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
});

export const documentsEditFormSchema = yup.object({
  id: yup.string(),
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  case: yup.object().shape({
    id: yup.string().required('Required *'),
    name: yup.string().required('Required *'),
    referenceId: yup.string().required('Required *'),
  }),
  plaintiffFullName: yup.string().required('Required *'),
  plaintiffId: yup.string(),
  fileId: yup.string(),
  documentUrl: yup.string(),
});

export const signaturesCreateFormSchema = yup.object({
  caseId: yup.object().shape({
    id: yup.string().required('Required *'),
    label: yup.string().required('Required *'),
  }),
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  documentId: yup.string().required('Required *'),
});

export const signaturesEditFormSchema = yup.object({
  id: yup.string(),
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  documentId: yup.string().required('Required *'),
  case: yup.object().shape({
    id: yup.string().required('Required *'),
    name: yup.string().required('Required *'),
  }),
  firm: yup.object().shape({
    id: yup.string().required('Required *'),
    name: yup.string().required('Required *'),
  }),
  plaintiffFullName: yup.string(),
  documentUrl: yup.string(),
  fileId: yup.string(),
});

export const dataCreateFormSchema = yup.object({
  caseId: yup.number().min(1).required(),
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  notes: yup.string().max(1000, 'Must be less or equal to 1000 characters'),
  message: yup.string().max(1000, 'Must be less or equal to 1000 characters'),
});

const itemSchema = yup.object().shape({
  id: yup.string().required('Required *'),
  code: yup.string().max(100, 'Max length 100').required('Required *'),
  systemName: yup.string().max(100, 'Max length 100').required('Required *'),
  questionText: yup.string().max(1000, 'Max length 1000').required('Required *'),
  immutable: yup.boolean().required('Required *'),
  dependsOnItem: yup.object().shape({
    booleanItemId: yup.string(),
    expectedValue: yup
      .string()
      .when('booleanItemId', ([booleanItemId], schema) =>
        booleanItemId ? schema.oneOf(Object.values(ExpectedAnswer)).required('Required *') : schema,
      ),
  }),
  format: yup.string().oneOf(Object.values(QuestionnaireItemFormat)).required('Required *'),
  placeholder: yup.string().max(100, 'Max length 100'),
  allowPartial: yup.boolean(),
});

export const questionnaireFormSectionSchema = yup.object().shape({
  title: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  items: yup
    .array()
    .of(itemSchema)
    .min(1, 'You must add at least 1 item')
    .max(1000, 'The maximum number of items is 1000')
    .required('Required *'),
  immutable: yup.boolean().required('Required *'),
});

export const questionnaireFormSchema = yup.object().shape({
  sections: yup
    .array()
    .of(questionnaireFormSectionSchema)
    .min(1, 'You must add at least 1 section')
    .max(500, 'The maximum number of sections is 500')
    .required('Required *'),
});

export const questionnaireEditFormSchema = yup.object().shape({
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  form: questionnaireFormSchema,
});

export const dataRequestQuestionnaireEditFormSchema = yup.object().shape({
  form: questionnaireFormSchema,
});

export const formSnippetSchema = yup.object().shape({
  items: yup
    .array()
    .of(itemSchema)
    .min(1, 'You must add at least 1 item')
    .max(1000, 'The maximum number of items is 1000')
    .required('Required *'),
});

export const formSnippetEditFormSchema = yup.object().shape({
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  data: formSnippetSchema,
});

export const dataEditFormSchema = yup.object().shape({
  form: questionnaireFormSchema.nullable(),
  answers: yup
    .array()
    .of(
      yup.object().shape({
        itemId: yup.string().required(),
        format: yup.string().oneOf(Object.values(QuestionnaireItemFormat)).required(),
        value: yup.mixed().nullable(),
      }),
    )
    .required(),
  status: yup.string().required(),
  id: yup.number().required(),
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  case: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    referenceId: yup.string().required(),
    type: yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
    }),
  }),
  firm: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }),
  plaintiff: yup.object().shape({
    id: yup.number().required(),
    firstName: yup.string().required(),
    middleName: yup.string(),
    lastName: yup.string().required(),
  }),
  plaintiffFullName: yup.string().required(),
  notes: yup.string().max(1000, 'Must be less or equal to 1000 characters'),
  message: yup.string().max(1000, 'Must be less or equal to 1000 characters'),
  formItemsCount: yup.number().required(),
  formItemAnswersCount: yup.number().required(),
});

export const signInFormSchema = yup.object({
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email format').required('Required *'),
  password: yup.string().required('Required *'),
});

export const changePasswordFormSchema = yup.object({
  newPassword: yup.string().required('Required *'),
  repeatNewPassword: yup
    .string()
    .required('Required *')
    // eslint-disable-next-line quotes
    .oneOf([yup.ref('newPassword'), ''], `Password doesn't match`),
});

export const forgotPasswordFormSchema = yup.object({
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email format').required('Required *'),
});

export const resetPasswordFormSchema = yup.object({
  code: yup
    .string()
    .required('Required *')
    .matches(new RegExp('^.{6}$'), 'Invalid verification code'),
  newPassword: yup.string().required('Required *'),
  repeatNewPassword: yup
    .string()
    .required('Required *')
    // eslint-disable-next-line quotes
    .oneOf([yup.ref('newPassword'), ''], `Password doesn't match`),
});

const validateCurrencyValue = (min: number, max: number, message: string) => {
  return yup.string().test('isValidMaximumAmount', message, function (value) {
    if (!value) return true;

    const amount = Number(value.replace(/,/g, ''));
    if (Number.isNaN(amount)) return false;
    if (amount < min || amount > max) return false;
    return true;
  });
};

export const caseAttorneysFeesFormSchema = yup.object({
  caseFeesConfig: yup.object().shape({
    variableFeePercent: validateCurrencyValue(0, 100, 'Valid values are from 0 to 100'),
    maximumAmount: validateCurrencyValue(
      0,
      9999999999.99,
      'Valid values are from 0 to 9999999999.99',
    ),
    variableFeeBasis: yup.string().required(),
  }),
  secondaryFirms: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          role: yup.string().oneOf(['CoCounsel', 'ReferringCounsel']).required(),
          id: yup.number().required(),
          name: yup.string().required(),
          config: yup
            .object()
            .shape({
              fixedFeeAmount: validateCurrencyValue(
                0,
                9999999999.99,
                'Valid values are from 0 to 9999999999.99',
              ),
              variableFeePercent: validateCurrencyValue(0, 100, 'Valid values are from 0 to 100'),
              variableFeeMaximumAmount: validateCurrencyValue(
                0,
                9999999999.99,
                'Valid values are from 0 to 9999999999.99',
              ),
              variableFeeBasis: yup.string().required(),
            })
            .required(),
        })
        .required(),
    )
    .min(0)
    .required(),
});

export const requestDemoSchema = yup.object({
  userName: yup
    .string()
    .max(100, 'Must be less or equal to 100 characters')
    .required('Required *')
    .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid name. Valid characters: a-z, A-Z, 0-9, -'),
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email format').required('Required *'),
  message: yup
    .string()
    .max(1000, 'Must be less or equal to 1000 characters')
    .required('Required *'),
  isAgreed: yup
    .boolean()
    .required('Agreement must be accepted')
    .oneOf([true], 'Agreement must be accepted'),
});

export const accountDeletionRequestFormSchema = yup.object({
  email: yup.string().required('* Required').matches(EMAIL_REGEX, 'Must be a valid email'),
});

export const requestSupportFormSchema = yup.object({
  name: yup
    .string()
    .max(100, 'Must be less or equal to 100 characters')
    .required('Required *')
    .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid name. Valid characters: a-z, A-Z, 0-9, -'),
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email format').required('Required *'),
  message: yup
    .string()
    .max(1000, 'Must be less or equal to 1000 characters')
    .required('Required *'),
});

export const emailConfirmationFormSchema = yup.object({
  ssn: yup
    .string()
    .required('Required *')
    .matches(
      /^(?!0{3})\d{3}-(?!0{2})\d{2}-(?!0{4})\d{4}$/,
      'Invalid SSN. Correct format: XXX-XX-XXXX',
    ),
  birthdate: yup
    .date()
    .transform((_, rawValue) => {
      const correctDate = moment(rawValue, ['mm-dd-yyyy']).toDate();
      return correctDate;
    })
    .min(moment(new Date(1900, 1, 1)).toDate(), 'Date is too early')
    .typeError('Invalid date')
    .required('Required *')
    .nullable()
    .max(moment(), 'Date is too far'),
});

export const editClientSSNDoBFormSchema = yup.object({
  ssn: yup
    .string()
    .required('Required *')
    .matches(
      /^(?!0{3})\d{3}-(?!0{2})\d{2}-(?!0{4})\d{4}$/,
      'Invalid SSN. Correct format: XXX-XX-XXXX',
    ),
  birthdate: yup
    .date()
    .transform((_, rawValue) => {
      const correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate();
      return correctDate;
    })
    .min(moment(new Date(1900, 1, 1)).toDate())
    .typeError('Invalid date')
    .required('Required *')
    .nullable()
    .max(moment(), 'Date is too far'),
});

export const confirm2FACodeFormSchema = yup.object({
  verificationCode: yup.string().required('Required *'),
});

export const firmBankingCreateUnrestrictedAccountFormSchema = yup.object().shape({
  accountNumber: yup
    .string()
    .required('Required *')
    .matches(/^[a-zA-Z0-9*:#-/&,+'`]{1,17}$/, 'Invalid format'),
  routingNumber: yup
    .string()
    .required('Required *')
    .matches(/^\d{9}$/, 'Invalid format'),
  name: yup.string().max(50, 'Must be less or equal to 50 characters').required('Required *'),
  isVerified: yup.bool().required(),
});

export const firmTrustAccountFormSchema = yup.object().shape({
  accountNumber: yup
    .string()
    .required('Required *')
    .matches(/^[a-zA-Z0-9*:#-/&,+'`]{1,17}$/, 'Invalid format'),
  routingNumber: yup
    .string()
    .required('Required *')
    .matches(/^\d{9}$/, 'Invalid format'),
  name: yup.string().max(50, 'Must be less or equal to 50 characters').required('Required *'),
  isVerified: yup.boolean().required(),
  wasUsedForPayment: yup.boolean().required(),
});

export const firmWithdrawFundsFormSchema = yup.object().shape({
  fromSystemAccountType: yup.string().required('Required *'),
  toUnrestrictedAccountId: yup.string().required('Required *'),
  amount: yup.string().required('Required *'),
});
