export interface FirmBankingSystemAccountData {
  accountNumber: string;
  routingNumber: string;
  balance: string;
  status: string;
  withdrawalAvailable: boolean;
}

export type IntegrationProvisioningData = {
  ofac?: {
    status: string;
    reason: string | null;
  };
  provisioning: {
    error?: {
      status: string;
      reason: string | null;
    };
    status: keyof typeof OFAC_STATUSES | keyof typeof INTEGRATION_STASUSES;
  };
};

export interface FirmBankingData {
  accounts: {
    exp: FirmBankingSystemAccountData | null;
    lien: FirmBankingSystemAccountData | null;
    rev: FirmBankingSystemAccountData | null;
  };
  canStartIntegration: boolean;
}

export enum INTEGRATION_STASUSES {
  InProgress = 'In Progress',
  Error = 'Error',
  CriticalError = 'Critical Error',
  Completed = 'Completed',
}

export enum OFAC_STATUSES {
  PENDING_VERIFICATION = 'Pending Verification',
  VERIFIED = 'Verified',
  UNDER_REVIEW = 'Under Review',
  REJECTED = 'Rejected',
  IGNORED = 'Ignored',
}

export enum SYSTEM_ACCOUNT_LABELS {
  rev = 'Revenue Recognition',
  exp = 'Expense Reimbursement',
  lien = 'Lien Resolution',
}

export enum SYSTEM_ACCOUNT_STATUSES {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  CLOSURE_INITIATED = 'CLOSURE_INITIATED',
  CLOSED = 'CLOSED',
}

export interface FirmUnrestrictedAccountShortData {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified?: boolean;
}

export interface FirmUnrestrictedAccountQueryData {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
  firm: {
    id: number;
    name: string;
  };
}

export interface FirmUnrestrictedAccountSubmitFormProps {
  accountNumber: string;
  routingNumber: string;
  name: string;
  isVerified: boolean;
}

export interface FirmUnrestrictedAccountSelectedTableItem {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified?: string;
}

export interface FirmTrustAccountData {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
  wasUsedForPayment: boolean;
}

export interface FirmTrustAccountSubmitFormProps {
  accountNumber: string;
  routingNumber: string;
  name: string;
  isVerified: boolean;
  wasUsedForPayment: boolean;
}

export interface FirmTrustAccountSelectedTableItem {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified?: string;
}

export interface FirmTrustAccountQueryShortData {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
  firm: {
    id: number;
    name: string;
  };
  caseTypes: Array<{
    id: number;
    name: string;
  }>;
}

export interface FirmTrustAccountQueryData extends FirmTrustAccountQueryShortData {
  wasUsedForPayment: boolean;
}

export interface FirmTrustAccountTableData {
  createdAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
}

export interface UnrestrictedAccountOptionsData {
  id: string;
  label: string;
}
export interface FirmBankingWithdrawFormSubmitProps {
  fromSystemAccountType: string;
  toUnrestrictedAccountId: string;
  amount: string;
}

export enum AccountTypeShortCuts {
  'Revenue Recognition' = 'REV',
  'Expense Reimbursement' = 'EXP',
  'Lien Resolution' = 'LIEN',
}
