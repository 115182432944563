import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';

import FirmUnrestrictedAccountPopup from './FirmUnrestrictedAccountPopup';
import FirmUnrestrictedAccountTableRow from './FirmUnrestrictedAccountTableRow';

import FirmBankingAddAccountButton from '../FirmBankingAddAccountButton';
import {
  FirmUnrestrictedAccountSelectedTableItem,
  FirmUnrestrictedAccountShortData,
  FirmUnrestrictedAccountSubmitFormProps,
  UnrestrictedAccountOptionsData,
} from '../types';

import {
  deleteFirmUnrestrictedAccount,
  getFirmUnrestrictedAccount,
  getFirmUnrestrictedAccounts,
  updateFirmUnrestrictedAccount,
} from '../../../api/firmsApi/unrestrictedAccountsApi';
import Loader from '../../../components/Loader';
import TableContainer from '../../../components/Table/TableContainer';
import NoDataText from '../../../components/NoDataText';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import { FIRM_BANKING_UNRESTRICTED_ACCOUNTS_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import useSnackBar from '../../../hooks/useSnackBar';
import usePagination from '../../../hooks/usePagination';
import { ActiveSortField, ErrorData } from '../../../types';
import { handleSortTableData } from '../../../helpers/handleSortTableData';
import { AxiosError } from 'axios';

const useStyles = makeStyles()((theme: Theme) => ({
  systemAccountsLabel: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '120%',
    color: theme.palette.common.white,
    fontFamily: 'Poppins',
  },
  tableHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',

    '@media (max-width: 800px)': {
      flexDirection: 'column',
      maxHeight: 'auto',
      gap: '12px',
    },
  },
}));

interface FirmUnrestrictedAccountsViewProps {
  setShortUnrestrictedAccountData: Dispatch<SetStateAction<UnrestrictedAccountOptionsData[]>>;
}

const FirmUnrestrictedAccountsView = ({
  setShortUnrestrictedAccountData,
}: FirmUnrestrictedAccountsViewProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();
  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [unrestrictedAccounts, setUnrestrictedAccountsData] = useState<
    FirmUnrestrictedAccountShortData[]
  >([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [accountIdToDelete, setAccountIdToDelete] = useState<number | null>(null);
  const [selectedTableItem, setSelectedTableItem] =
    useState<FirmUnrestrictedAccountShortData | null>(null);
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);

  const firmId = localStorage.getItem('firmId');
  const sortableFields = {
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
  };

  const fetchUnrestrictedAccounts = () => {
    getFirmUnrestrictedAccounts(
      { page: pageNumber, size: rowsPerPage, count: true, activeSortField },
      firmId as string,
    )
      .then((res) => {
        if (res.data) {
          setTotalCount && setTotalCount(res.data.totalCount as number);

          const parsedAccountData = res.data.resultSet.map((resultItem) => ({
            id: resultItem.id,
            createdAt: moment(resultItem.createdAt).format('M/D/YYYY'),
            name: resultItem.name,
            accountNumber: resultItem.accountNumber,
            routingNumber: resultItem.routingNumber,
            isVerified: resultItem.isVerified,
          }));

          const parsedShortData = res.data.resultSet.map((resultItem) => ({
            id: String(resultItem.id),
            label: resultItem.name,
          }));

          setShortUnrestrictedAccountData(parsedShortData);
          setUnrestrictedAccountsData(parsedAccountData);
          setIsDataLoading(false);
        }
      })
      .catch(() => {
        setIsDataLoading(false);
      });
  };

  const handleEditAccount = (accountFormData: FirmUnrestrictedAccountSubmitFormProps) => {
    if (!selectedTableItem) return;

    updateFirmUnrestrictedAccount(selectedTableItem.id, accountFormData)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'Unrestricted account updated successfully.',
          type: 'success',
        }));

        fetchUnrestrictedAccounts();
        handleClosePopup();
        setSelectedTableItem(null);
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleDeleteAccount = () => {
    if (!accountIdToDelete) return;

    deleteFirmUnrestrictedAccount(accountIdToDelete)
      .then(() => {
        setAccountIdToDelete(null);
        handleCloseDeletePopup();
        fetchUnrestrictedAccounts();

        setAlert((prev) => ({
          ...prev,
          message: 'Unrestricted account deleted successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAccountIdToDelete(null);
        handleCloseDeletePopup();

        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const onEditButtonClick = (tableItem: FirmUnrestrictedAccountSelectedTableItem) => {
    handleOpenPopup();

    getFirmUnrestrictedAccount(tableItem.id)
      .then(({ data }) => {
        setSelectedTableItem({
          createdAt: data.createdAt,
          id: data.id,
          name: data.name,
          accountNumber: data.accountNumber,
          routingNumber: data.routingNumber,
          isVerified: data.isVerified,
        });
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      });
  };

  const onDeleteButtonClick = (accountId: number) => {
    setAccountIdToDelete(accountId);
    handleOpenDeletePopup();
  };

  const handleResetFormState = () => {
    setSelectedTableItem(null);
    handleClosePopup();
  };

  const handleOpenDeletePopup = () => setIsDeletePopupOpen(true);
  const handleCloseDeletePopup = () => setIsDeletePopupOpen(false);
  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  useEffect(() => {
    fetchUnrestrictedAccounts();
  }, [pageNumber, rowsPerPage, activeSortField]);

  return (
    <Box mt="40px">
      <Box className={classes.tableHeadline}>
        <Typography className={classes.systemAccountsLabel}>Unrestricted Accounts</Typography>
        <FirmBankingAddAccountButton
          buttonText="Add Unrestricted Account"
          handleClick={handleOpenPopup}
        />
      </Box>

      {isDataLoading && !unrestrictedAccounts.length ? (
        <Loader colorType="warning"></Loader>
      ) : (
        <>
          {!isDataLoading && unrestrictedAccounts.length === 0 ? (
            <NoDataText />
          ) : (
            <TableContainer
              headerLabels={FIRM_BANKING_UNRESTRICTED_ACCOUNTS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <FirmUnrestrictedAccountTableRow
                accountsData={unrestrictedAccounts}
                onEditButtonClick={onEditButtonClick}
                onDeleteButtonClick={onDeleteButtonClick}
              />
            </TableContainer>
          )}

          <FirmUnrestrictedAccountPopup
            isOpen={isPopupOpen}
            headlineText={`${selectedTableItem ? 'Edit' : 'Add'} Unrestricted Account`}
            handleEditAccount={handleEditAccount}
            selectedTableItem={selectedTableItem}
            handleResetFormState={handleResetFormState}
            handleClosePopup={handleClosePopup}
            fetchUnrestrictedAccounts={fetchUnrestrictedAccounts}
          />

          <Popup
            isOpen={isDeletePopupOpen}
            onMainButtonClick={handleDeleteAccount}
            onSecondaryButtonClick={handleCloseDeletePopup}
            headlineText={POPUP_DESCRIPTIONS.unrestrictedAccountDelete.headlineText}
            contentText={POPUP_DESCRIPTIONS.unrestrictedAccountDelete.contentText}
          />
        </>
      )}
    </Box>
  );
};

export default FirmUnrestrictedAccountsView;
