import { Box, TableCell, TableRow } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import FirmBankingTableActionButton from '../FirmBankingTableActionButton';
import { FirmTrustAccountSelectedTableItem, FirmTrustAccountTableData } from '../types';

interface FirmTrustAccountTableRowProps {
  accountsData: FirmTrustAccountTableData[];
  onEditButtonClick: (tableItem: FirmTrustAccountSelectedTableItem) => void;
  onDeleteButtonClick: (accountId: number) => void;
}

const FirmTrustAccountTableRow = ({
  accountsData,
  onEditButtonClick,
  onDeleteButtonClick,
}: FirmTrustAccountTableRowProps) => {
  const formattedAccountsData =
    accountsData &&
    accountsData.map((account) => ({
      id: account.id,
      createdAt: account.createdAt,
      name: account.name,
      accountNumber: account.accountNumber,
      routingNumber: account.routingNumber,
      isVerified: account.isVerified ? 'Verified' : 'Unverified',
    }));

  return (
    <>
      {formattedAccountsData.map((tableItem) => (
        <TableRow key={tableItem.id}>
          {Object.entries(tableItem).map(([key, value], idx) => (
            <>{key !== 'id' && <TableCell key={idx}>{value}</TableCell>}</>
          ))}
          <TableCell>
            <Box display="flex" gap="12px" alignItems="center">
              <FirmBankingTableActionButton
                type="info"
                buttonIcon={
                  <ModeEditOutlineOutlinedIcon
                    sx={{
                      fontSize: '20px',
                    }}
                  />
                }
                handleClick={() => onEditButtonClick(tableItem)}
              />
              <FirmBankingTableActionButton
                type="error"
                buttonIcon={
                  <DeleteOutlineOutlinedIcon
                    sx={{
                      fontSize: '20px',
                    }}
                  />
                }
                handleClick={() => onDeleteButtonClick(tableItem.id)}
              />
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default FirmTrustAccountTableRow;
