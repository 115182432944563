import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as LinkRoute, useLocation } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, Theme, useMediaQuery } from '@mui/material';

import { Confirm2FACodeFormProps } from './types';
import { ReactComponent as LogoIcon } from './sign-logo.svg';

import { getUser } from '../../api/usersApi/usersApi';
import { onLogIn, send2FAcode } from '../../api/authApi/authApi';
import CountdownTimer from '../../components/CountdownTimer';
import FormMaskedTextField from '../../components/formFields/FormMaskedTextField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import { CONFIRM_2FA_CODE_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { confirm2FACodeFormSchema } from '../../helpers/formSchemas/formSchemas';
import useAuth from '../../hooks/useAuth';
import useSnackBar from '../../hooks/useSnackBar';

const useStyles = makeStyles()((theme: Theme) => ({
  signInBackground: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to left bottom, rgba(37, 157, 168, 0.19), rgba(37, 157, 168, 0))',
    backgroundColor: '#282B3A',

    '@media (max-width: 640px)': {
      padding: '0px 16px',
    },

    '@media (max-height: 530px)': {
      height: 'auto',
    },
  },
  signInFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.light,
    borderRadius: '16px',
    border: `1px solid ${theme.palette.secondary.dark}`,
    padding: '32px',
    marginTop: '60px',
    maxWidth: '480px',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '64px',

    '@media (max-width: 640px)': {
      padding: '16px',
    },
  },
  signInFormHeadline: {
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '34px',
    letterSpacing: '0px',
    color: theme.palette.common.white,

    '@media (max-width: 640px)': {
      fontSize: '22px',
      lineHeight: '30px',
    },
  },
  signInFormText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '120%',
    color: theme.palette.text.secondary,
  },
  signInFormLink: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    letterSpacing: '0px',
    color: theme.palette.info.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  signInFormBottomLink: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: '0px',
    color: theme.palette.info.main,
    textAlign: 'center',
    textDecoration: 'none',
  },
  signInLogoContainer: {
    '@media (max-height: 700px)': {
      marginTop: '40px',
    },
  },
}));

const CODE_LENGTH = 6;

const Confirm2FACodeView = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const sm = useMediaQuery('(max-width:640px)');

  const { checkAuth, loading, isAuth } = useAuth();
  const { setAlert } = useSnackBar();
  const [isCountdownActive, setIsCountdownActive] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  useEffect(() => {
    checkAuth();
  }, []);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(confirm2FACodeFormSchema),
    defaultValues: CONFIRM_2FA_CODE_FORM_DEFAULT_VALUES,
  });

  const { handleSubmit, watch } = formMethods;

  const codeFieldValue = watch('verificationCode');
  const isFormValid = codeFieldValue.length === CODE_LENGTH;

  const onSubmit = (values: Confirm2FACodeFormProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      email: location.state?.email,
      password: location.state?.password,
      verificationCode: values.verificationCode,
    };

    const getUserByToken = async (token: string) => {
      const userTokenData: { [x: string]: string } = jwtDecode(token);

      await getUser(userTokenData.username).then((res) => {
        if (res.data) {
          localStorage.setItem('role', res.data.role);
          localStorage.setItem('firmId', res.data.firm.id);
          localStorage.setItem('userId', res.data.id.toString());

          navigate('/cases');
        }
      });
    };

    onLogIn(submitData)
      .then((res) => {
        if (res.data) {
          const accessToken = res.data.accessToken;
          const refreshToken = res.data.refreshToken;

          localStorage.setItem('refresh_token', refreshToken);
          localStorage.setItem('access_token', accessToken);

          getUserByToken(accessToken);
        }
      })
      .catch((err) => {
        if (err) {
          setIsFormSubmitting(false);
          setAlert((prev) => ({
            ...prev,
            message: err.response.data.message,
            type: 'error',
          }));
        }
      });
  };

  const handleResendCode = () => {
    const submitData = {
      email: location.state?.email,
      password: location.state?.password,
    };

    send2FAcode(submitData)
      .then((res) => {
        if (res.data) {
          setAlert((prev) => ({
            ...prev,
            message: 'Verification code has been resent successfully.',
            type: 'success',
          }));
          setIsCountdownActive(true);
        }
      })
      .catch((err) => {
        if (err) {
          setAlert((prev) => ({
            ...prev,
            message: err.response.data.message,
            type: 'error',
          }));
        }
      });
  };

  if (loading) return null;
  if (isAuth) return <Navigate to="/cases" />;

  return (
    <Box className={styles.classes.signInBackground}>
      <Box className={styles.classes.signInLogoContainer}>
        <LinkRoute to="/">
          <LogoIcon />
        </LinkRoute>
      </Box>

      <FormProvider {...formMethods}>
        <form className={styles.classes.signInFormContainer}>
          <Typography className={styles.classes.signInFormHeadline}>
            Enter the verification code sent to your email.
          </Typography>
          <Typography className={styles.classes.signInFormText} mt="8px">
            Note: Your verification code will expire in 5 minutes.
          </Typography>

          <Box mt={sm ? '16px' : '32px'}>
            <FormMaskedTextField mask="999999" name="verificationCode" label="6 digit code" />
          </Box>

          <Box display="flex" mt="22px" width="100%" justifyContent="center" gap="12px">
            <Typography className={styles.classes.signInFormText}>Didn’t get your code?</Typography>
            {isCountdownActive ? (
              <Box display="flex" gap="4px">
                <Typography className={styles.classes.signInFormText}> Resend code in </Typography>
                <CountdownTimer
                  isCountdownActive={isCountdownActive}
                  onCountdownEnd={() => setIsCountdownActive(false)}
                  countdownTime={location.state.countdownTime}
                />
              </Box>
            ) : (
              <Box onClick={handleResendCode}>
                <Typography className={styles.classes.signInFormLink}>Resend code</Typography>
              </Box>
            )}
          </Box>

          <Box mt={sm ? '16px' : '32px'}>
            <FormSubmitButton
              buttonText="Submit"
              disabled={!isFormValid || isFormSubmitting}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default Confirm2FACodeView;
